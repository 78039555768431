<template>
  <section class="section">
    <h4 class="title has-text-centered is-4">Feats</h4>
    <feat-list :feats="featsData"></feat-list>

    <b-collapse :open="false" class="mt-6">
      <template #trigger>
        <b-button
          label="Create New Feat"
          icon-left="plus"
          type="is-info" />
      </template>
      <feat-form></feat-form>
    </b-collapse>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import FeatList from '../../components/Feats/FeatList.vue';
import FeatForm from '../../components/Feats/FeatForm.vue';

export default {
  name: 'Feats',
  components: { FeatForm, FeatList },
  computed: {
    ...mapState('feats', ['loadingFeats', 'featsData']),
  },
  mounted() {
    this.loadFeats();
  },
  methods: {
    loadFeats() {
      this.$store.dispatch('feats/loadFeats');
    },
    goToFeat(row) {
      this.$router.push({ path: `/feats/${row.id}` });
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
</style>
